import { Redirect } from "react-router-dom"
import { styled } from "styled-components"

import {
  CallAssistGridItem,
  GuardAssistGridItem,
} from "src/components/ResponseService/AssistServices/ServicesGridItem"
import { useGetCallAssistActive } from "src/data/callAssist/queries/callAssistQueries"
import { useFetchResponseServiceHomes } from "src/data/homes/queries/responseServiceQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MainView } from "src/ui/Layout/MainView"
import { spacing } from "src/ui/spacing"

export function AssistServices() {
  const { t, langKeys } = useTranslate()

  const title = t(langKeys.call_and_guard_assist)
  const description = t(langKeys.call_and_guard_assist_description)
  const getResponseServiceActive = useGetResponseServiceActive()

  const { isInitialLoading, callAssistIsActive } = useGetCallAssistActive()

  if (isInitialLoading || getResponseServiceActive.query.isInitialLoading) {
    return null
  }

  if (callAssistIsActive) {
    return <Redirect to={Routes.CallAssist.location()} />
  }
  if (getResponseServiceActive.active) {
    return <Redirect to={Routes.ResponseService.location()} />
  }

  return (
    <MainView title={title} titleBarProps={{ description }}>
      <ServicesGrid>
        <CallAssistGridItem />
        <GuardAssistGridItem />
      </ServicesGrid>
    </MainView>
  )
}

const ServicesGrid = styled.div`
  display: grid;
  gap: ${spacing.XL2};
`

function useGetResponseServiceActive() {
  const fetchResponseServiceHomes = useFetchResponseServiceHomes({})
  const active = (fetchResponseServiceHomes.data?.homes?.length ?? 0) >= 1

  return { query: fetchResponseServiceHomes, active }
}
