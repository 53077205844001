import { useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import styled from "styled-components"

import { getFormattedCallAssistCost } from "src/components/CallAssistActivate/getFormattedCallAssistCost"
import {
  HREF_MINUT_MARKETING_CALL_ASSIST_INFO,
  HREF_MINUT_MARKETING_GUARD_ASSIST_INFO,
} from "src/constants/hrefs"
import { useAppData } from "src/context/useAppData"
import { useFetchCallAssistActivationEstimate } from "src/data/callAssist/queries/callAssistQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton, TMButtonProps } from "src/ui/Button/MButton"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import PhotoCallAssist from "src/ui/images/photo-call-assist-1.jpg"
import PhotoGuardAssist from "src/ui/images/photo-guard-assist-1.jpg"
import { LearnMore } from "src/ui/Link/LearnMore"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import "react-lazy-load-image-component/src/effects/opacity.css"

type ServicesItemProps = {
  name: React.ReactNode
  title: React.ReactNode
  children: React.ReactNode
  activateHref: NonNullable<TMButtonProps["appHref"]>
  learnMoreHref: string
  imgSrc: string
  blockGetStarted?: boolean
}
function ServicesItem({
  name,
  title,
  children,
  activateHref,
  learnMoreHref,
  imgSrc,
  blockGetStarted,
}: ServicesItemProps) {
  const [showBlockDialog, setShowBlockDialog] = useState(false)

  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()

  return (
    <div>
      <Container>
        <ServicesItemBox>
          <ImageBox src={imgSrc} alt="" effect="opacity"></ImageBox>

          <div>
            <MText variant="subtitle">{name}</MText>
            <MText variant="heading2">{title}</MText>

            <MText variant="body" color="secondary">
              {children}
            </MText>

            <ServicesItemActions>
              <MButton
                onClick={() => {
                  if (blockGetStarted) {
                    setShowBlockDialog(true)
                    return
                  }

                  navigate(activateHref)
                }}
                variant="secondary"
              >
                {t(langKeys.get_started)}
              </MButton>

              <LearnMore href={learnMoreHref} color="primary" />
            </ServicesItemActions>
          </div>
        </ServicesItemBox>
      </Container>
      <ConfirmDialog
        title={t(langKeys.subscription_upgrade_blocker_title)}
        open={showBlockDialog}
        onClose={() => setShowBlockDialog(false)}
        onConfirm={() => setShowBlockDialog(false)}
        confirmLabel={t(langKeys.close)}
      >
        <MText>
          {t(langKeys.subscription_assist_activation_blocker_description)}
        </MText>
      </ConfirmDialog>
    </div>
  )
}

const Container = styled.div`
  container-type: inline-size;
`

const ImageBox = styled(LazyLoadImage)`
  border-radius: 8px;
  object-fit: cover;

  /* Necessary since LazyLoadImage wraps this component in a span: */
  height: 100%;
  width: 100%;
`

const ServicesItemActions = styled.div`
  display: flex;
  gap: ${spacing.M};
  align-items: center;
`

const ServicesItemBox = styled.div`
  display: grid;
  gap: ${spacing.L} ${spacing.XL2};

  @container (min-width: 600px) {
    grid-template-columns: 280px 1fr;
    /* Col1 customizations */
    & > :first-child {
      max-height: 280px;
      aspect-ratio: 1;
    }
    /* Col2 customizations */
    & > :last-child {
      max-width: 65ch;
    }
  }
`

export function CallAssistGridItem() {
  const { currentSubscription } = useAppData()
  const { org } = useOrganization()

  const { t, langKeys } = useTranslate()

  const fetchCallAssistActivationEstimate =
    useFetchCallAssistActivationEstimate({
      options: { enabled: !!currentSubscription },
    })
  const callAssistEstimate = fetchCallAssistActivationEstimate.data
  const { unitPriceMonthly } = callAssistEstimate
    ? getFormattedCallAssistCost(callAssistEstimate)
    : { unitPriceMonthly: null }

  function getPrice() {
    if (fetchCallAssistActivationEstimate.isError) {
      return "$10"
    }
    if (unitPriceMonthly == null) {
      return <MSkeleton width="5ch" inline />
    }
    return unitPriceMonthly
  }
  const price = getPrice()
  const month = t(langKeys.month).toLocaleLowerCase()
  const home = t(langKeys.home).toLocaleLowerCase()

  return (
    <ServicesItem
      name={t(langKeys.call_assist)}
      title={t(langKeys.call_assist_title_description)}
      activateHref={Routes.CallAssistActivate.location()}
      learnMoreHref={HREF_MINUT_MARKETING_CALL_ASSIST_INFO}
      imgSrc={PhotoCallAssist}
      blockGetStarted={org.billing_model !== "plan_unit_per_home"}
    >
      <p>{t(langKeys.call_assist_description)}</p>
      <p>
        {price}/{month}/{home}
      </p>
    </ServicesItem>
  )
}

export function GuardAssistGridItem() {
  const { org } = useOrganization()

  const { t, langKeys } = useTranslate()

  const price = `${t(langKeys.from_price, { price: "$10" })}/${t(
    langKeys.month
  )}/${t(langKeys.home)}`.toLocaleLowerCase()

  return (
    <ServicesItem
      name={t(langKeys.response_service)}
      title={t(langKeys.guard_assist_title_description)}
      activateHref={Routes.ResponseService.location()}
      learnMoreHref={HREF_MINUT_MARKETING_GUARD_ASSIST_INFO}
      imgSrc={PhotoGuardAssist}
      blockGetStarted={org.billing_model !== "plan_unit_per_home"}
    >
      <p>{t(langKeys.guard_assist_description)}</p>
      <p>{price}</p>
    </ServicesItem>
  )
}
